@let isMenuPage = pageIs().menu;
@let isInvoicePage = pageIs().invoice;

<div
  class="tab-bar"
  [class.hide]="hideTabBar()"
>
  <div class="tabs" coreActivitySection="TabBar">
    <button
      class="tab side-tab"
      [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isMenuPage ? 'MenuClose' : 'MenuOpen'"
    >
      <img src="/images/menu-1.svg" alt=""/>
    </button>

    <button
      class="tab call-menu show"
      routerLink="/main/home"
      routerLinkActive="active"
      coreUserActivityClickTracking="PageClose"
    >
      <img src="/images/dennys-logo-1.png" alt=""/>
    </button>

    @if (withInvoice) {
      <button
        class="tab side-tab pay"
        [routerLink]="isInvoicePage ? '/main/home' : '/main/invoice'"
        routerLinkActive="active"
        [coreUserActivityClickTracking]="isInvoicePage ? 'InvoicePageClose' : 'InvoicePageOpen'"
      >
        <img src="/images/bill-1.svg" alt=""/>
      </button>
    }
  </div>
</div>

@if (hideTabBar()) {
  <core-interacts-present/>
}
